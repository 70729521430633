<template>
  <div>
    <div class="box-shadow bg-white">
      <div class="d-flex-center notice-bar p-20 mb-30">
        <div v-for="(item, index) in noticeList" :key="index">
          <el-link
            type="primary"
            :title="item.title"
            class="link"
            @click="$nav.push('/notice/details/' + item.id)"
            >{{ item.title }}</el-link
          >
          <span class="mx-20">/</span>
        </div>
        <div>
          <el-link type="primary" class="link" @click="$nav.push('/notice')">{{
            $t("n_more")
          }}</el-link>
        </div>
      </div>
    </div>
    <el-card class="content-box">
      <div class="top-info py-20">
        <div class="left-textcontent">
          <h1 class="mr-auto pair-coin my-0">
            {{ $route.params.pair.toUpperCase() }} /
            {{ $route.params.coin.toUpperCase() }}
          </h1>
        </div>

        <div class="right-textcontent">
          <div class="price">
            <div
              class="font-24 price-value"
              :class="{
                'color-s': info.change > 0,
                'color-d': info.change < 0,
              }"
            >
              {{ info.lasest_price }}
            </div>
            <div class="mt-5 color-6 font-14">= {{ info.usd }} (USD)</div>
          </div>
          <div class="change text-center">
            <div v-if="info.change > 0" class="font-20 color-s">
              +{{ info.change }}%
            </div>
            <div v-else-if="info.change == 0" class="font-20">
              {{ info.change }}%
            </div>
            <div v-else class="font-20 color-d">{{ info.change }}%</div>
            <div class="mt-5 color-6 font-14">24H {{ $t("home.change") }}</div>
          </div>
          <div class="high text-center">
            <div class="font-20">{{ info.high }}</div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.high") }} ({{ $route.params.coin }})
            </div>
          </div>
          <div class="low text-center">
            <div class="font-20">{{ info.low }}</div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.low") }} ({{ $route.params.coin }})
            </div>
          </div>
        </div>
      </div>
      <div class="kline-box mt-20">
        <div class="kline-tools font-bold">
          <!-- k线面积图 -->
          <el-button
            :type="Period == 4 && drawType ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(4, true)"
            >Time</el-button
          >

          <el-button
            :type="Period == 4 && !drawType ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(4, false)"
            >1Min</el-button
          >
          <el-button
            :type="Period == 5 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(5)"
            >5Min</el-button
          >
          <el-button
            :type="Period == 6 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(6)"
            >15Min</el-button
          >
          <el-button
            :type="Period == 7 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(7)"
            >30Min</el-button
          >
          <el-button
            :type="Period == 0 ? 'success' : 'primary'"
            size="mini"
            plain
            class="bg-tw-50"
            @click="ChangePeriod(0)"
            >1Day</el-button
          >
        </div>
        <div
          class="kline-view"
          ref="kline"
          style="height: 500px; width: 1160px; position：relative"
        />
      </div>
    </el-card>
    <div class="content-box mb-50 mt-20">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="bg-white box-shadow border-r">
            <div class="tabs d-flex-center border-bottom font-14">
              <div
                class="tab px-25 pt-20 flex-1 mr-auto"
                :class="{ active: marketCoin == 'USDT' }"
                @click="onTab('USDT')"
              >
                USDT
              </div>
              <!-- <div
                class="tab px-25 pt-20 flex-1"
                :class="{ active: marketCoin == 'BTC' }"
                @click="onTab('BTC')"
              >
                BTC
              </div>
              <div
                class="tab px-25 pt-20 flex-1"
                :class="{ active: marketCoin == 'ETH' }"
                @click="onTab('ETH')"
              >
                ETH
              </div> -->
            </div>
            <el-row
              :gutter="10"
              type="flex"
              justify="center"
              align="middle"
              class="color-gray px-20 py-15 font-12"
            >
              <el-col :span="8">Pair</el-col>
              <el-col :span="10">Price</el-col>
              <el-col class="text-right" :span="6">Change</el-col>
            </el-row>
            <div
              v-for="(item, index) in market"
              :key="index"
              class="is-link"
              @click="$nav.replace('/trading/' + item.pair + '/' + item.coin)"
            >
              <el-row
                :gutter="10"
                type="flex"
                justify="center"
                align="middle"
                class="market-body px-20 py-15"
              >
                <el-col :span="3">
                  <i
                    v-if="item.logo"
                    class="icon icon-stars"
                    :style="{ backgroundImage: 'url(' + item.logo + ')' }"
                  />
                  <i
                    v-else
                    class="icon icon-stars"
                    :class="'icon-coin-' + item.pair.toLowerCase()"
                  />
                </el-col>
                <el-col :span="5" class="font-14">{{
                  item.pair.toUpperCase()
                }}</el-col>
                <el-col :span="10" class="font-14">{{
                  item.lasest_price
                }}</el-col>
                <el-col :span="6" class="text-right">
                  <el-tag
                    v-if="item.change > 0"
                    class="change"
                    type="success"
                    effect="dark"
                    size="small"
                    >+{{ item.change }}%</el-tag
                  >
                  <el-tag
                    v-else-if="item.change == 0"
                    class="change"
                    type="warning"
                    effect="dark"
                    size="small"
                    >0.00%</el-tag
                  >
                  <el-tag
                    v-else
                    class="change"
                    type="danger"
                    effect="dark"
                    size="small"
                    >{{ item.change }}%</el-tag
                  >
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <!-- 下单模块 -->
          <div class="bg-white box-shadow border-r mb-20 tabs-outline">
            <!-- @tab-click="handleClick" -->
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
              class="newstyle"
            >
              <!--  -->
              <!-- 现货 市價 -->
              <!-- trading_index.el_title -->
              <el-tab-pane :label="$t('trading_index.tab_1')" name="first">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="newstyle-col">
                      <el-form
                        ref="buyFormShijia"
                        v-loading="buyLoading_shijia"
                        class="input-form mx-auto"
                        :model="buyFormData_shijia"
                        :rules="rules"
                      >
                        <el-form-item>
                          <div class="youjia">
                            <span>{{$t('youjia')}}</span>
                          </div>
                        </el-form-item>
                        <el-form-item prop="all_buy_shijia">
                          <el-input
                            v-model="buyFormData_shijia.all_buy_shijia"
                            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                            @input="allBuyValueFnShijia"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('allBuyValueFnShijia_p')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input>
                        </el-form-item>

                        <div
                          class="
                            mb-20
                            d-flex-center
                            font-14 font-bold
                            color-666
                          "
                        >
                          <span class="mr-auto"
                            >{{ $t("contact_about.available") }}
                            {{ $route.params.coin }} :
                          </span>
                          <span>{{ userInfo.coin }}</span>
                        </div>

                        <!-- 拖条 -->
                        <!-- 買入 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider
                                v-model="slider_value_shijia"
                                :show-tooltip="false"
                                class="slider_style_class"
                                input-size="mini"
                                @input="sliderFn_shijia"
                              >
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{ slider_value_shijia ? slider_value_shijia + "%" : 0 }}
                            </div>
                          </div>
                        </el-form-item>

                        <el-form-item>
                          <el-button
                            type="success"
                            icon="el-icon-s-promotion"
                            class="w-100"
                            native-type="button"
                            @click="submitBuyForm_shijia()"
                            >{{$t('b_buy')}}</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="newstyle-col">
                      <el-form
                        ref="sellFormShijia"
                        v-loading="sellLoading_shijia"
                        class="input-form mx-auto"
                        :model="sellFormData_shijia"
                        :rules="rules"
                      >
                        <el-form-item>
                          <div class="youjia">
                            <span>{{$t('youjia')}}</span>
                          </div>
                        </el-form-item>
                        <el-form-item prop="all_sell_shijia">
                          <el-input
                            @input="allSellValueFnShijia"
                            v-model="sellFormData_shijia.all_sell_shijia"
                            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('allSellValueFnShijia_p')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.pair
                            }}</span>
                          </el-input>
                        </el-form-item>

                        <!-- 可用余额 -->
                        <div
                          class="
                            mb-20
                            d-flex-center
                            font-14 font-bold
                            color-666
                          "
                        >
                          <span class="mr-auto"
                            >{{ $t("contact_about.available") }}
                            {{ $route.params.pair }} :
                          </span>
                          <span>{{ userInfo.pair }}</span>
                        </div>

                        <!-- 拖条 -->
                        <!-- 賣出 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider
                                v-model="slider_value_shijia1"
                                :show-tooltip="false"
                                class="slider_style_class"
                                input-size="mini"
                                @input="sliderFn_shijia1"
                              >
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{ slider_value_shijia1 ? slider_value_shijia1 + "%" : 0 }}
                            </div>
                          </div>
                        </el-form-item>

                        <el-form-item>
                          <el-button
                            type="danger"
                            icon="el-icon-s-promotion"
                            class="w-100"
                            native-type="button"
                            @click="submitSellForm_shijia()"
                            >{{$t('b_sell')}}</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!-- 现货 市價 -end -->

              <!-- 现货 限價 -->
              <!-- trading_index.el_title -->
              <el-tab-pane :label="$t('trading_index.tab_2')" name="second">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="newstyle-col">
                      <el-form
                        ref="buyForm"
                        v-loading="buyLoading"
                        class="input-form mx-auto"
                        :model="buyFormData"
                        :rules="rules"
                      >
                        <el-form-item prop="price">
                          <el-input
                            v-model="buyFormData.price"
                            @input="buyPriceFn"
                            @focus="formInputFocus"
                            type="number"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('price')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="amount">
                          <el-input
                            v-model="buyFormData.amount"
                            @input="buyAmountFn"
                            type="number"
                            prefix-icon="el-icon-coin"
                            :placeholder="$t('contact_about.num')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.pair
                            }}</span>
                          </el-input>
                        </el-form-item>

                        <div
                          class="
                            mb-20
                            d-flex-center
                            font-14 font-bold
                            color-666
                          "
                        >
                          <span class="mr-auto"
                            >{{ $t("contact_about.available") }}
                            {{ $route.params.coin }} :
                          </span>
                          <span>{{ userInfo.coin }}</span>
                        </div>

                        <!-- 拖条 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider
                                v-model="slider_value"
                                :show-tooltip="false"
                                class="slider_style_class"
                                input-size="mini"
                                @input="sliderFn"
                              >
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{ slider_value ? slider_value + "%" : 0 }}
                            </div>
                          </div>
                        </el-form-item>

                        <el-form-item>
                          <!-- readonly 禁止填寫 -->
                          <el-input
                            v-model="all_buy"
                            @input="allBuyValueFn"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('total')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input>
                        </el-form-item>

                        <el-form-item>
                          <el-button
                            type="success"
                            icon="el-icon-s-promotion"
                            class="w-100"
                            native-type="button"
                            @click="submitBuyForm()"
                            >{{$t('b_buy')}}</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="newstyle-col">
                      <el-form
                        ref="sellForm"
                        v-loading="sellLoading"
                        class="input-form mx-auto"
                        :model="sellFormData"
                        :rules="rules"
                      >
                        <el-form-item prop="price">
                          <el-input
                            v-model="sellFormData.price"
                            @input="sellPriceFn"
                            @focus="formInputFocus"
                            type="number"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('price')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="amount">
                          <el-input
                            v-model="sellFormData.amount"
                            @input="sellAmountFn"
                            type="number"
                            prefix-icon="el-icon-coin"
                            :placeholder="$t('contact_about.num')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.pair
                            }}</span>
                          </el-input>
                        </el-form-item>

                        <!-- 可用余额 -->
                        <div
                          class="
                            mb-20
                            d-flex-center
                            font-14 font-bold
                            color-666
                          "
                        >
                          <span class="mr-auto"
                            >{{ $t("contact_about.available") }}
                            {{ $route.params.pair }} :
                          </span>
                          <span>{{ userInfo.pair }}</span>
                        </div>

                        <!-- 拖条 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider
                                v-model="slider_value1"
                                :show-tooltip="false"
                                class="slider_style_class"
                                input-size="mini"
                                @input="sliderFn1"
                              >
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{ slider_value1 ? slider_value1 + "%" : 0 }}
                            </div>
                          </div>
                        </el-form-item>

                        <el-form-item>
                          <!-- readonly 禁止填寫 -->
                          <el-input
                            @input="allSellValueFn"
                            v-model="all_sell"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('total')"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input>
                        </el-form-item>

                        <el-form-item>
                          <el-button
                            type="danger"
                            icon="el-icon-s-promotion"
                            class="w-100"
                            native-type="button"
                            @click="submitSellForm()"
                            >{{$t('b_sell')}}</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </div>

          <!-- 订单列表 -->
          <div class="bg-white box-shadow border-r" v-loading="tradingLoading">
            <el-tabs
              v-model="activeName1"
              @tab-click="handleClick1"
              class="newstyle"
            >
              <el-tab-pane :label="$t('trading_index.el_title1')" name="first">
                <div class="etab-top">
                  <div class="title-switch">
                    <el-switch
                      v-model="switch1"
                      @change="changeFn1"
                      :active-text="$t('trading_index.el_switch1')"
                    >
                    </el-switch>
                  </div>
                </div>

                <el-row
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="color-gray py-15 border-bottom font-14"
                >
                  <el-col :span="4">{{
                    $t("contact_about.table2.type")
                  }}</el-col>
                  <el-col :span="6">{{
                    $t("contact_about.table2.price")
                  }}</el-col>
                  <el-col :span="5">{{
                    $t("contact_about.table2.amount")
                  }}</el-col>

                  <el-col :span="4">{{
                    $t("contact_about.table2.time")
                  }}</el-col>
                  <el-col class="text-right" :span="4">{{
                    $t("contact_about.table2.deal")
                  }}</el-col>
                </el-row>
                <el-row
                  v-for="(item, index) in tradingList"
                  :key="index"
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="py-15 border-bottom font-14"
                >
                  <el-col :span="4">
                    <el-tag
                      v-if="item.type"
                      type="info"
                      effect="plain"
                      hit
                      class="bg-t"
                      >Sell</el-tag
                    >
                    <el-tag
                      v-else
                      type="primary"
                      effect="plain"
                      hit
                      class="bg-t"
                      >Buy</el-tag
                    >
                  </el-col>
                  <el-col :span="6">{{ item.entrust_price }}</el-col>
                  <el-col :span="5">{{ item.amount }}</el-col>

                  <el-col :span="4">{{ item.create_time }}</el-col>
                  <el-col class="text-right" :span="4">
                    <el-button
                      type="danger"
                      size="small"
                      plain
                      class="bg-t"
                      @click="onCancelTradingOrder(item.id)"
                      >Cancel</el-button
                    >
                  </el-col>
                </el-row>

                <div
                  v-if="tradingList.length < 1"
                  class="py-50 font-14 color-i text-center"
                >
                  No More
                </div>

                <div class="pagination-box" v-else>
                  <el-pagination
                    layout="prev, pager, next"
                    background
                    :total="total1"
                    :page-size="pageSize1"
                    :current-page="pageNum1"
                    @current-change="pageFn1"
                  >
                  </el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bg-white box-shadow border-r">
            <el-row
              :gutter="20"
              type="flex"
              justify="center"
              align="middle"
              class="border-bottom px-20 py-15 font-12"
            >
              <el-col :span="6">{{ $t("middle_window.Position") }}</el-col>
              <el-col :span="9">{{ $t("middle_window.Price") }}</el-col>
              <el-col class="text-right" :span="9">{{
                $t("middle_window.Amount")
              }}</el-col>
            </el-row>
            <div v-if="mbp.asks.length == 5" class="mbp-sell color-s">
              <div
                v-for="index in [4, 3, 2, 1, 0]"
                :key="index"
                @click="onSetPrice(mbp.asks[index][0])"
                class="is-link"
              >
                <el-row
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="border-bottom font-12 px-20 py-10"
                >
                  <el-col :span="6" class="font-14">Sell{{ index + 1 }}</el-col>
                  <el-col :span="9" class="font-14">{{
                    mbp.asks[index][0]
                  }}</el-col>
                  <el-col :span="9" class="text-right">{{
                    Number(mbp.asks[index][1]).toFixed(8) * 1
                  }}</el-col>
                </el-row>
              </div>
            </div>
            <div v-if="mbp.bids.length == 5" class="mbp-buy color-d">
              <div
                v-for="index in [0, 1, 2, 3, 4]"
                :key="index"
                @click="onSetPrice(mbp.bids[index][0])"
                class="is-link"
              >
                <el-row
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="border-bottom font-12 px-20 py-10"
                >
                  <el-col :span="6" class="font-14">Buy{{ index + 1 }}</el-col>
                  <el-col :span="9" class="font-14">{{
                    mbp.bids[index][0]
                  }}</el-col>
                  <el-col :span="9" class="text-right">{{
                    Number(mbp.bids[index][1]).toFixed(8) * 1
                  }}</el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import HQChart from "hqchart";
HQChart.Chart.JSConsole.Chart.Log = () => {};
HQChart.Chart.JSConsole.Complier.Log = () => {};
import request from "@/services/axios";
//黑色风格
function GetDevicePixelRatio() {
  return window.devicePixelRatio || 1;
}
const BLACK_STYLE = {
  BGColor: "rgb(23,26,37)", //背景色
  TooltipBGColor: "rgb(255, 255, 255)", //背景色
  TooltipAlpha: 0.92, //透明度

  SelectRectBGColor: "rgba(1,130,212,0.06)", //背景色
  //  SelectRectAlpha: 0.06;                  //透明度

  //K线颜色
  UpBarColor: "rgb(25,158,0)", //上涨
  DownBarColor: "rgb(238,21,21)", //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  Minute: {
    VolBarColor: "rgb(255,236,0)", //分时图成交量柱子颜色（已经不用了 使用红绿柱)
    PriceColor: "rgb(25,180,231)", //分时图价格线颜色
    AreaPriceColor: "rgba(63,158,255,.3)", //价格的面积图
    AvPriceColor: "rgb(255,236,0)", //分时图均价线颜色
    PositionColor: "rgb(218,165,32)", //持仓量线段颜色
  },

  DefaultTextColor: "rgb(255,255,255)",
  DefaultTextFont: 14 * GetDevicePixelRatio() + "px 微软雅黑",
  TitleFont: 13 * GetDevicePixelRatio() + "px 微软雅黑", //标题字体(动态标题 K线及指标的动态信息字体)

  //K线信息文字
  DownTextColor: "rgb(238,21,21)", //下跌
  UpTextColor: "rgb(25,158,0)", //上涨
  UnchagneTextColor: "rgb(101,104,112)", //平盘
  CloseLineColor: "rgb(178,34,34)", //收盘价线

  FrameBorderPen: "rgba(236,236,236,0.13)", //边框
  FrameSplitPen: "rgba(236,236,236,0.13)", //分割线
  FrameSplitTextColor: "rgb(101,104,112)", //刻度文字颜色
  FrameSplitTextFont: 12 * GetDevicePixelRatio() + "px 微软雅黑", //坐标刻度文字字体
  FrameTitleBGColor: "rgb(31,38,54)", //标题栏背景色

  Frame: { XBottomOffset: 1 * GetDevicePixelRatio() }, //X轴文字向下偏移

  CorssCursorBGColor: "rgb(43,54,69)", //十字光标背景
  CorssCursorTextColor: "rgb(255,255,255)",
  CorssCursorTextFont: 12 * GetDevicePixelRatio() + "px 微软雅黑",
  CorssCursorPenColor: "rgb(130,130,130)", //十字光标线段颜色

  KLine: {
    MaxMin: {
      Font: 12 * GetDevicePixelRatio() + "px 微软雅黑",
      Color: "rgb(111,111,111)",
    }, //K线最大最小值显示
    //信息地雷
    Info: {
      Color: "rgb(205,149,12)",
      TextColor: "#afc0da",
      TextBGColor: "#1a283e",
    },
  },

  Index: {
    //指标线段颜色
    LineColor: [
      "rgb(255,189,09)",
      "rgb(22,198,255)",
      "rgb(174,35,161)",
      "rgb(236,105,65)",
      "rgb(68,114,196)",
      "rgb(229,0,79)",
      "rgb(0,128,255)",
      "rgb(252,96,154)",
      "rgb(42,230,215)",
      "rgb(24,71,178)",
    ],
    NotSupport: { Font: "14px 微软雅黑", TextColor: "rgb(52,52,52)" },
  },

  //自定义指标默认颜色
  ColorArray: [
    "rgb(255,174,0)",
    "rgb(25,199,255)",
    "rgb(175,95,162)",
    "rgb(236,105,65)",
    "rgb(68,114,196)",
    "rgb(229,0,79)",
    "rgb(0,128,255)",
    "rgb(252,96,154)",
    "rgb(42,230,215)",
    "rgb(24,71,178)",
  ],

  //画图工具
  DrawPicture: {
    LineColor: "rgb(30,144,255)",
    PointColor: "rgb(105,105,105)",
  },
};
const LIGHT_STYLE = {
  //K线颜色
  UpBarColor: "rgb(25,158,0)", //上涨
  DownBarColor: "rgb(238,21,21)", //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  //K线信息文字
  DownTextColor: "rgb(238,21,21)", //下跌
  UpTextColor: "rgb(25,158,0)", //上涨
  UnchagneTextColor: "rgb(101,104,112)", //平盘
  CloseLineColor: "rgb(178,34,34)", //收盘价线
};

const STYLE_TYPE_ID = {
  LIGTH_ID: 0, //黑色风格
  BLACK_ID: 1, //黑色风格
};

function HQChartStyle() {}

HQChartStyle.GetStyleConfig = function (
  styleid //获取一个风格的配置变量
) {
  switch (styleid) {
    case STYLE_TYPE_ID.LIGHT_ID:
      return LIGHT_STYLE;
    case STYLE_TYPE_ID.BLACK_ID:
      return BLACK_STYLE;
    default:
      return null;
  }
};
let Period = 0;

export default {
  data() {
    return {
      chart: null,
      drawType: false,
      coinLogo,
      noticeList: [],
      info: {
        // price: "0.0000",
        // usd: "0.00",
        // change: 0,
        // accuracy: 2,
        // high: "0.00",
        // low: "0.00",
        // volume: "0.00",
      },
      Period: 0,
      KLine: {
        JSChart: null,
        Option: {
          // Type: "分钟走势图",
          Type: "历史K线图",
          // Language: this.$store.state.locale == "zh-TW" ? "TC" : "EN",
          Language: "EN",
          SplashTitle:
            this.$store.state.locale == "zh-TW" ? "數據加載中" : "Loading",
          //   NetworkFilter: NetworkFilter,
          IsShowRightMenu: false,
          IsAutoUpdate: false,
          IsApiPeriod: true,
          IsShowCorssCursorInfo: true,
          CorssCursorInfo: { Left: 2, Right: 2, IsShowCorss: true },
          Windows: [
            {
              Index: "MA",
              Modify: true,
              Change: true,
            },
            {
              Index: "VOL",
              Modify: true,
              Change: true,
            },
          ],
          Border: {
            Left: 0,
            Right: 0, //右边间距
            Top: 25,
            Bottom: 25,
          },

          Frame: [
            {
              SplitCount: 3,
              SplitType: 1,
              // YCoordinateType:2,
              // SplitCount:8,
              // BorderLine:false,
              // IsShowLeftText: false,
              // IsShowXLine:false,IsShowYLine:false,
              // FrameSplitPen: "red",          //分割线
              // FrameSplitTextColor: "red",     //刻度文字颜色
              // FrameTitleBGColor: "red",      //标题栏背景色
              Height: 8,
              TopSpace: 0,
              BottomSpace: 0,
              IsShowLeftText: false, //不显示左边刻度文字
              IsShowRightText: true, //显示右边刻度文字
              Custom: [
                {
                  Type: 0,
                  Position: "right",
                  // IsShowLine: false,
                  LineType: 1,
                  Data: {
                    TextColor: "black",
                  },
                },
              ],
              Data: {
                TextColor: "black",
              },
            },
            {
              SplitCount: 3,
              SplitType: 1,
              IsShowIndexName: false,
              IsShowLeftText: false,
              IsShowRightText: false,
              Height: 2,
              TopSpace: 0,
              BottomSpace: 0,
              // IsShowXLine: false,
              // IsShowYLine: false
            },
          ],

          KLine: {
            Right: 1, //复权 0 不复权 1 前复权 2 后复权
            Period: 0, //周期: 0 日线 1 周线 2 月线 3 年线
            PageSize: 70,
            IsShowTooltip: false,
            // DrawType:4
          },
          KLineTitle: {
            IsShowName: false,
            IsShowSettingInfo: false,
          },
        },
      },
      mbp: {
        bids: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        asks: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
      },
      klineLoading: true,
      loading: false,
      dataVersion: 0,
      timer: null,
      // klineSrc: null,
      marketCoin: this.$store.state.marketCoin,
      market: [],
      marketLoading: false,
      marketTimer: null,
      marketDataVersion: 0,
      //
      userInfo: {
        pair: 0,
        coin: 0,
        usdt: 0,
      },
      buyLoading: false,
      buyFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      buyFormData1: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      sellLoading: false,
      sellFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      sellFormData1: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      tradingLoading: false,
      tradingList: [],
      contactList: [],
      rules: {
        price: [
          { required: true, trigger: "blur", message: this.$t("rfields") },
        ],
        amount: [
          { required: true, trigger: "blur", message: this.$t("rfields") },
        ],
        pay_password: [{ required: true, trigger: "blur" }],

        // 市價買入
        all_buy_shijia: [
          { required: true, trigger: "blur", message: this.$t("rfields") },
        ],
        // 市價賣出
        all_sell_shijia: [
          { required: true, trigger: "blur", message: this.$t("rfields") },
        ],
      },
      orderLoading: false,
      orderShow: false,
      rate: 0,
      lastDueTime: 30,
      dueTimer: null,
      dueTimeList: {
        30: 75,
        60: 80,
        90: 85,
        180: 90,
        300: 95,
      },
      orderFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        due_time: 30,
        type: 0,
        amount: "100",
        total: "78",
        id: 1,
        state: 1,
      },

      coinList: ["usdt", "btc", "eth"],

      //新增
      marketLength: 0,
      socket1: null,
      activeName: "first",
      activeName1: "first",
      lever1: 5,
      options1: [
        {
          value: 5,
          label: "5x",
        },
        {
          value: 10,
          label: "10x",
        },
        {
          value: 20,
          label: "20x",
        },
        {
          value: 30,
          label: "30x",
        },
        {
          value: 50,
          label: "50x",
        },
        {
          value: 75,
          label: "75x",
        },
        {
          value: 100,
          label: "100x",
        },
      ],
      userContract: {
        net_worth: "", //净值
        balance: "", //可用余额
        income: "", //持仓收益
        cash_deposit_total: "", //占用保证金
      },
      total1: 0,
      total2: 0,
      pageSize1: 5,
      pageSize2: 5,
      pageNum1: 1,
      pageNum2: 1,
      switch1: true,
      switch2: true,
      labelState: 0,

      slider_value: 0,
      slider_value1: 0,
      all_buy: "",
      all_sell: "",
      new_price_input: "",

      //現貨交易類型 
      tab_index:0,
      //市價
      slider_value_shijia:0,
      slider_value_shijia1:0,
      // all_buy_shijia:'',
      // all_sell_shijia:'',

      buyLoading_shijia: false,
      sellLoading_shijia: false,
      buyFormData_shijia: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: '',
        amount: '',
        pay_password: '',

        //市價買入
        all_buy_shijia: '',
      },
      // buyFormData1_shijia: {
      //   pair: this.$route.params.pair,
      //   coin: this.$route.params.coin,
      //   price: null,
      //   amount: null,
      //   pay_password: null,
      // },
      sellFormData_shijia:{
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: '',
        amount: '',
        pay_password: '',

        //市價買入
        all_sell_shijia: '',

      },

      Cache: [],

      digit_unit: 0,
    };
  },
  computed: {
    buyTotalValue: function () {
      if (!this.buyFormData.price || !this.buyFormData.amount) return "";
      return (this.buyFormData.price * this.buyFormData.amount).toFixed(8) * 1;
    },
    buyTotalValue1: function () {
      if (!this.buyFormData1.price || !this.buyFormData1.amount) return "";
      // return (this.buyFormData1.price * this.buyFormData1.amount).toFixed(8) * 1;
      //保证金 = (价格 / 杠杆) * 数量
      return this.floatingpoint.mul(
        this.floatingpoint.bed(this.buyFormData1.price, this.lever1),
        this.buyFormData1.amount
      );
    },
    sellTotalValue: function () {
      // if(!this.sellFormData.amount) return '--'
      // return this.sellFormData.amount
      if (!this.sellFormData.price || !this.sellFormData.amount) return "";
      return (
        (this.sellFormData.price * this.sellFormData.amount).toFixed(8) * 1
      );
    },
    sellTotalValue1: function () {
      // if(!this.sellFormData.amount) return '--'
      // return this.sellFormData.amount
      if (!this.sellFormData1.price || !this.sellFormData1.amount) return "";
      // return (
      //   (this.sellFormData1.price * this.sellFormData1.amount).toFixed(8) * 1
      // );
      //保证金 = (价格 / 杠杆) * 数量
      return this.floatingpoint.mul(
        this.floatingpoint.bed(this.sellFormData1.price, this.lever1),
        this.sellFormData1.amount
      );
    },
    expectedProfits: function () {
      if (!this.orderFormData.amount) return "0";
      return (
        // this.orderFormData.amount *
        // (this.dueTimeList[this.orderFormData.due_time] / 100 + 1)
        this.floatingpoint.mul(
          this.orderFormData.amount,
          this.dueTimeList[this.orderFormData.due_time] / 100 + 1
        )
      );
    },
    currentRate() {
      if (this.lastDueTime == 0 || this.orderFormData.due_time == 0) return 0;
      if (this.lastDueTime >= this.orderFormData.due_time) return 100;
      const num =
        (this.lastDueTime / this.orderFormData.due_time).toFixed(2).valueOf() *
        100;
      return num;
    },
  },
  watch: {
    marketCoin: function () {
      this.market = [];
      if (["BTC", "ETH", "USDT"].indexOf(this.marketCoin) < 0) return;
      this.$store.commit("marketCoin", this.marketCoin);
      this.tabChange(true);
    },
    $route: function () {
      // this.fatchData();

      this.tabChange(true);

      this.klineLoading = true;
      this.klineSrc =
        "kline.html?symbol=" +
        this.$route.params.pair +
        this.$route.params.coin +
        "&times=86400000";
      this.fatchUserInfo();

      this.total1 = 0;
      this.total2 = 0;
      this.pageNum1 = 1;
      this.pageNum2 = 1;
      this.switch1 = true;
      this.switch2 = true;
      this.labelState = 0;

      this.fatchTradingList();
      //   this.fatchContactList();
      this.buyFormData.pair = this.$route.params.pair;
      this.buyFormData.coin = this.$route.params.coin;
      this.buyFormData.price = null;
      this.sellFormData.pair = this.$route.params.pair;
      this.sellFormData.coin = this.$route.params.coin;
      this.sellFormData.price = null;
      this.orderFormData.pair = this.$route.params.pair;
      this.orderFormData.coin = this.$route.params.coin;
      this.orderFormData.amount = "10";

      this.buyFormData_shijia = {}
      this.sellFormData_shijia = {}
      this.buyFormData_shijia.pair = this.$route.params.pair;
      this.buyFormData_shijia.coin = this.$route.params.coin;
      this.buyFormData_shijia.price = '';
      this.buyFormData_shijia.amount = '';
      this.buyFormData_shijia.pay_password = '';
      this.buyFormData_shijia.all_buy_shijia = '';
      this.sellFormData_shijia.pair = this.$route.params.pair;
      this.sellFormData_shijia.coin = this.$route.params.coin;
      this.sellFormData_shijia.price = '';
      this.sellFormData_shijia.amount = '';
      this.sellFormData_shijia.pay_password = '';
      this.sellFormData_shijia.all_sell_shijia = '';

      this.new_price_input = null

      this.CreateKLineChart();

      //   this.fatchUserContract();
    },
  },
  created: function () {
    this.fatchNotice();
    // this.klineSrc = 'kline.html?symbol='+this.$route.params.pair+this.$route.params.coin
    // this.fatchData();
    // this.timer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   this.fatchData();
    // }, 900);
    this.tabChange(true);
    // this.marketTimer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   if (["BTC", "ETH", "USDT"].indexOf(this.marketCoin) < 0) return;
    //   this.tabChange(false);
    // }, 1900);
    if (localStorage.getItem("userToken")) {
      this.fatchUserInfo();
    }
    this.fatchTradingList();
    // this.fatchContactList();

    // this.fatchUserContract();
  },
  beforeDestroy: function () {
    window.clearInterval(this.timer);
    window.clearInterval(this.marketTimer);
    window.clearInterval(this.dueTimer);
  },
  mounted: function () {
    Period = 0;
    this.Period = 0;
    this.CreateKLineChart();
  },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    //k線數據交互
    NetworkFilter(data, callback) {
      let that = this;
      //网络协议回调
      // console.log('[NetworkFilter] data', data);
      data.PreventDefault = true; //设置hqchart不請求数据

      if (
        data.Name != "KLineChartContainer::RequestHistoryData" &&
        data.Name != "KLineChartContainer::ReqeustHistoryMinuteData"
      ) {
        return;
      }

      let timeJson = {
        time_4: 60000,
        time_5: 300000,
        time_6: 900000,
        time_7: 1800000,
        time_0: 216000000,
      };

      request
        .post("v1/symbol/getSymbolKine", {
          //   symbol: this.Symbol,
          symbol: this.$route.params.pair + this.$route.params.coin,
          times: timeJson["time_" + this.Period],
        })
        .then((res) => {
          //动态设置默认小数位数
          HQChart.Chart.MARKET_SUFFIX_NAME.GetDefaultDecimal = (symbol) => {
            that.digit_unit = res.data.digit;
            return res.data.digit;
          };
          callback(res.data);
          this.StartWSUpdate(); //加载ws拼接
        })
        .catch((err) => {
          console.error(err);
        });
    },

    //加载ws拼接
    StartWSUpdate() {
      this.socket1.on("symbol_kline", (res) => {
        let $json = JSON.parse(res);
        if (this.Period == 4) {
          // console.log($json["1m"])
          this.OnRecvWSRealtimeData($json["1m"]);
        }
        if (this.Period == 5) {
          this.OnRecvWSRealtimeData($json["5m"]);
        }
        if (this.Period == 6) {
          this.OnRecvWSRealtimeData($json["15m"]);
        }
        if (this.Period == 7) {
          this.OnRecvWSRealtimeData($json["30m"]);
        }
        if (this.Period == 0) {
          this.OnRecvWSRealtimeData($json["1d"]);
        }
      });
    },

    StopWSUpdate() {}, //停止ws更新数据

    // 数据拼接示例
    OnRecvWSRealtimeData(data) {
      // console.log("[KLineChart::OnRecvWSRealtimeData", data);

      if (data) {
        var hqChartData = { code: 0, data: [], ver: 2.0 };
        hqChartData.digit = this.digit_unit;
        hqChartData.symbol = this.$route.params.pair + this.$route.params.coin;
        hqChartData.name = this.$route.params.pair + this.$route.params.coin;
        // data.forEach((item, index) => {
        //   item = Number(item);
        // });
        for (var i in data) {
          data[i] = Number(data[i]);
          // let val1 = data[6]
          // let val2 = data[7]
          // data[6] = val2
          // data[7] = val1
        }
        hqChartData.data.push(data);
        this.chart.JSChartContainer.RecvMinuteRealtimeData(hqChartData);
      }
    },

    CreateKLineChart: function () {
      if (this.KLine.JSChart) {
        this.KLine.JSChart.ChangeSymbol(
          this.$route.params.pair + this.$route.params.coin
        );
        return;
      }
      this.KLine.Option.Symbol =
        this.$route.params.pair + this.$route.params.coin;
      this.KLine.Option.Period = Period;
      this.KLine.Option.KLine.Period = Period;//2023.2.15 图标光标的时分跟着走

      // if(Period==4){
      //   // this.KLine.Option.KLine.DrawType = 4
      //   console.log(this.KLine)
      // }

      // const black = HQChart.Chart.HQChartStyle.GetStyleConfig(HQChart.Chart.STYLE_TYPE_ID.BLACK_ID)
      const style = HQChartStyle.GetStyleConfig(STYLE_TYPE_ID.LIGHT_ID);
      // HQChart.Chart.JSChart.SetStyle(black)
      HQChart.Chart.JSChart.SetStyle(style);
      this.chart = HQChart.Chart.JSChart.Init(this.$refs.kline);
      this.KLine.Option.NetworkFilter = this.NetworkFilter;
      this.chart.SetOption(this.KLine.Option);
      this.KLine.JSChart = this.chart;
    },
    ChangePeriod: function (period, drawType) {
      Period = period;
      this.Period = period;
      this.drawType = drawType;
      if (this.KLine.JSChart) {
        // if(Period==4&&drawType){
        //   // this.drawType = drawType
        //   this.KLine.Option.KLine.DrawType = 4
        // }else{
        //   this.KLine.Option.KLine.DrawType = 0
        // }
        if (Period == 4 && drawType) {
          // this.drawType = drawType;
          this.KLine.Option.KLine.DrawType = 4;
          //显示为k线面积图的时候去掉指标
          // this.KLine.Option.Windows[0].Change = false
          // this.KLine.Option.Windows[0].Modify = false
          this.KLine.Option.Windows[0].Index = "EMPTY";
          // this.KLine.Option.Windows[0].TitleHeight = 0
        } else {
          this.KLine.Option.Windows[0].Index = "MA";
          this.KLine.Option.KLine.DrawType = 0;
        }
        this.KLine.Option.KLine.Period = Period;//2023.2.15
        this.chart.SetOption(this.KLine.Option);
        this.KLine.JSChart = this.chart;
        // this.KLine.JSChart.ChangePeriod(period);
        return;
      }
    },
    fatchNotice: async function () {
      const { data } = await this.$request.post("v1/notice");
      this.noticeList = data.data;
    },

    fatchData: function () {
      if (this.loading) return;
      this.dataVersion++;
      let version = Number(this.dataVersion);
      this.loading = true;
      // console.log('market_trade/index/pair/'+this.$route.params.pair+'/coin/'+this.$route.params.coin)
      this.$requestNotState
        .get(
          "market_trade/index/pair/" +
            this.$route.params.pair +
            "/coin/" +
            this.$route.params.coin
        )
        .then(({ data }) => {
          if (version == this.dataVersion) {
            this.info = data.market;
            // data.mbp.asks = data.mbp.asks.reverse()
            this.mbp = data.mbp;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onKlineLoad: function () {
      this.klineLoading = false;
    },
    onTab: function (active) {
      this.marketCoin = active;
    },

    tabChange: function (ignoreLoadingStatus = false) {
      let that = this
      this.marketLoading = true;
      this.$requestNotState
        .post("v1/symbol/getList", {
          coin: this.marketCoin.toLowerCase(),
        })
        .then(({ data }) => {
          // this.market = data
          let arr = {};
          if (data.list) {
            data.list.forEach(function (item, index) {
              // 新增，先获取默认最新价格
              if(item.pair==that.$route.params.pair&&item.coin==that.$route.params.coin){
                that.new_price_input = item.lasest_price
              }

              arr[item.pair + "" + item.coin] = item;
            });
          }
          this.info =
            arr[this.$route.params.pair + "" + this.$route.params.coin];
          this.market = arr;
          this.getdata_wss();
          this.marketLoading = false;
        })
        .catch(() => {
          this.marketLoading = false;
        });
    },

    //获取动态数据
    getdata_wss: function () {
      if (this.socket1) {
        this.socket1.close(); //离开路由之后断开websocket连接
      }

      //wss實時請求
      // this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
      this.socket1 = this.$io(this.$wsn+"/symbol", {
        query: {},
        transports: ["websocket", "polling"],
        timeout: 5000,
        // pingTimeout: 20000,
        // pingInterval: 25000,
      });

      this.socket1.on("connect_error", (reason) => {
        console.log(reason);
      });

      this.socket1.on("connect", (socket) => {
        // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
        console.log("ws 已连接");

        //全部报价
        this.socket1.emit("symbol_price_list");
        //单一报价
        this.socket1.emit(
          "symbol_price",
          this.$route.params.pair + "" + this.$route.params.coin
        );
        //交易数据
        this.socket1.emit(
          "symbol_mbp",
          this.$route.params.pair + "" + this.$route.params.coin
        );

        // 2023.5.12新增的动态k线数据
        this.socket1.emit(
          "symbol_kline",
          this.$route.params.pair + "" + this.$route.params.coin
        );

        this.socket1.on("symbol_price_list", (res) => {
          this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin]
            ? (this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin] =
                JSON.parse(res))
            : "";
          this.market = JSON.parse(JSON.stringify(this.market));

          // 匹配最新的价格
          if(JSON.parse(res).pair==this.$route.params.pair&&JSON.parse(res).coin==this.$route.params.coin){
            this.new_price_input = JSON.parse(res).lasest_price;
          }
        });

        this.socket1.on("symbol_price", (res) => {
          this.info = JSON.parse(res);

          // //输入框焦点获取价格
          // this.new_price_input = JSON.parse(res).lasest_price;

          // this.info.change = JSON.parse(res).change;
          // this.info.coin = JSON.parse(res).coin;
          // this.info.high = JSON.parse(res).high;
          // this.info.lasest_price = JSON.parse(res).lasest_price;
          // this.info.low = JSON.parse(res).low;
          // this.info.pair = JSON.parse(res).pair;
          // this.info.usd = JSON.parse(res).usd;
          // this.info.volume_24h = JSON.parse(res).volume_24h;
        });

        this.socket1.on("symbol_mbp", (res) => {
          this.mbp = JSON.parse(res);
        });
      });

      this.socket1.on("disconnect", (reason) => {
        console.log(reason);
      });

      this.socket1.on("error", (msg) => {
        console.log("ws error", msg);
      });
    },

    //获取现货钱包余额
    fatchUserInfo: async function () {
      console.log(111111)
      this.userInfo = {
        pair: 0,
        coin: 0,
        usdt: 0,
      };
      try {
        const { data } = await this.$request.post(
          "v1/symbol/getAccountBalance",
          { coin: this.$route.params.coin, pair: this.$route.params.pair }
        );
        this.userInfo.pair = data.balance.balance_pair;
        this.userInfo.coin = data.balance.balance_coin;
        this.userInfo.usdt = data.balance.balance_coin;
      } catch (e) {
        //TODO handle the exception
      }
    },
    onSetPrice: function (price) {
      this.buyFormData.price = price;
      this.sellFormData.price = price;
    },

    //现货买入 - 市價
    submitBuyForm_shijia: async function () {
      try {
        await this.$refs.buyFormShijia.validate();
        // if (
        //   this.floatingpoint.mul(
        //     Number(this.buyFormData.price),
        //     Number(this.buyFormData.amount)
        //   ) > Number(this.userInfo.coin)
        // ) {
        //   return this.$message({
        //     showClose: true,
        //     type: "error",
        //     duration: 2000,
        //     message: this.$t("trading_index.message1"),
        //   });
        // }
        if (
          Number(this.buyFormData_shijia.all_buy_shijia) > Number(this.userInfo.coin)
        ) {
          return this.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: this.$t("shijia_chao"),
          });
        }
        this.buyLoading_shijia = true;
        this.buyFormData_shijia.money = ''
        this.buyFormData_shijia.money = Number(this.buyFormData_shijia.all_buy_shijia) + ''
        this.buyFormData_shijia.type = this.tab_index
        this.buyFormData_shijia.symbol =
          this.buyFormData_shijia.pair + "" + this.buyFormData_shijia.coin;

        let $data = await this.$request.post(
          "v1/symbol/buy",
          this.buyFormData_shijia
        );
        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            // 拖條
            that.slider_value_shijia = 0

            that.buyFormData_shijia.all_buy_shijia = '';
            that.buyFormData_shijia.money = '';
            that.buyFormData_shijia.price = '';
            that.buyFormData_shijia.amount = '';
            that.buyFormData_shijia.pay_password = '';
            that.buyFormData_shijia.symbol = '';
            that.$refs.buyFormShijia.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.buyLoading_shijia = false;
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.fatchTradingList();
            that.fatchUserInfo()
          },
        });
      } catch (error) {
        this.buyLoading_shijia = false;
      }
    },

    //现货賣出 - 市價
    submitSellForm_shijia: async function () {
      try {
        await this.$refs.sellFormShijia.validate();

        if (Number(this.sellFormData_shijia.all_sell_shijia) > Number(this.userInfo.pair)) {
          return this.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: this.$t("shijia_chao"),
          });
        }

        this.sellLoading_shijia = true;

        this.sellFormData_shijia.amount = ''
        this.sellFormData_shijia.amount = Number(this.sellFormData_shijia.all_sell_shijia) + ''
        this.sellFormData_shijia.type = this.tab_index

        this.sellFormData_shijia.symbol =
          this.sellFormData_shijia.pair + "" + this.sellFormData_shijia.coin;
        let $data = await this.$request.post(
          "v1/symbol/sell",
          this.sellFormData_shijia
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            // 拖條all_sell_shijia
            that.slider_value_shijia1 = 0
            that.sellFormData_shijia.all_sell_shijia = ''
            that.sellFormData_shijia.amount = '';

            that.sellFormData_shijia.price = '';
            that.sellFormData_shijia.pay_password = '';
            that.sellFormData_shijia.symbol = '';
            that.$refs.sellFormShijia.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.sellLoading_shijia = false;
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.fatchTradingList();
            that.fatchUserInfo()
          },
        });
      } catch (error) {
        this.sellLoading_shijia = false;
      }
    },

    //现货买入 - 限價
    submitBuyForm: async function () {
      try {
        await this.$refs.buyForm.validate();
        if (
          this.floatingpoint.mul(
            Number(this.buyFormData.price),
            Number(this.buyFormData.amount)
          ) > Number(this.userInfo.coin)
        ) {
          return this.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: this.$t("trading_index.message1"),
          });
        }

        this.buyLoading = true;
        this.buyFormData.type = this.tab_index
        this.buyFormData.symbol =
          this.buyFormData.pair + "" + this.buyFormData.coin;

        //轉字符串 
        this.buyFormData.amount = this.buyFormData.amount +''
        this.buyFormData.price = this.buyFormData.price +''

        let $data = await this.$request.post(
          "v1/symbol/buy",
          this.buyFormData
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.slider_value = 0
            that.buyFormData.price = '';
            that.buyFormData.amount = '';
            that.buyFormData.pay_password = '';
            that.buyFormData.symbol = '';
            that.$refs.buyForm.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.buyLoading = false;
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.all_buy = "";
            that.fatchTradingList();
            that.fatchUserInfo()
          },
        });
      } catch (error) {
        this.buyLoading = false;
      }
    },

    //现货卖出 - 限價
    submitSellForm: async function () {
      try {
        await this.$refs.sellForm.validate();

        if (this.sellFormData.amount > Number(this.userInfo.pair)) {
          return this.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: this.$t("trading_index.message2"),
          });
        }

        this.sellLoading = true;
        this.sellFormData.type = this.tab_index
        this.sellFormData.symbol =
          this.sellFormData.pair + "" + this.sellFormData.coin;

        //轉字符串 
        this.sellFormData.amount = this.sellFormData.amount +''
        this.sellFormData.price = this.sellFormData.price +''

        let $data = await this.$request.post(
          "v1/symbol/sell",
          this.sellFormData
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.slider_value1 = 0
            that.sellFormData.price = '';
            that.sellFormData.amount = '';
            that.sellFormData.pay_password = '';
            that.sellFormData.symbol = '';
            that.$refs.sellForm.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.sellLoading = false;
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.labelState = 0;
            that.all_sell = "";
            that.fatchTradingList();
            that.fatchUserInfo()
          },
        });
      } catch (error) {
        this.sellLoading = false;
      }
    },

    //输入框焦点获取价格
    formInputFocus: function () {
      if (!this.buyFormData.price) {
        this.buyFormData.price = this.new_price_input;
      }
      if (!this.sellFormData.price) {
        this.sellFormData.price = this.new_price_input;
      }
    },

    //监听买入价格框buyPriceFn
    buyPriceFn(value = null) {
      if (value !== null) this.buyFormData.price = value;
      if (!this.buyFormData.price) {
        return;
      } else if (this.buyFormData.amount) {
        this.all_buy =
          Math.round(
            this.buyFormData.price * this.buyFormData.amount * 100000000
          ) / 100000000;
        this.all_buy = this.floatingpoint.toolNumber(this.all_buy);
      } else if (this.all_buy) {
        this.buyFormData.amount =
          Math.round((this.all_buy / this.buyFormData.price) * 100000000) /
          100000000;
        this.buyFormData.amount = this.floatingpoint.toolNumber(this.buyFormData.amount);
      }
    },

    //监听买入数量框buyAmountFn
    buyAmountFn(value = null) {
      if (value !== null) this.buyFormData.amount = value;
      if (!this.buyFormData.price || !this.buyFormData.amount) {
        this.all_buy = "";
        return;
      } else {
        this.all_buy =
          Math.round(
            this.buyFormData.price * this.buyFormData.amount * 100000000
          ) / 100000000;
        this.all_buy = this.floatingpoint.toolNumber(this.all_buy);
      }
    },


    // 市價交易監聽
    //监听买入
    // value = null
    allBuyValueFnShijia(value) {
      // console.log(this.buyFormData_shijia.all_buy_shijia)
      // if (value !== null) this.all_buy = value;
      // if (!this.buyFormData.price || !this.all_buy) {
      //   this.buyFormData.amount = "";
      //   return;
      // } else {
      //   this.buyFormData.amount =
      //     Math.round((this.all_buy / this.buyFormData.price) * 100000000) /
      //     100000000;
      // }
    },
    //监听卖出
    allSellValueFnShijia(value) {
      // console.log(this.sellFormData_shijia.all_sell_shijia)
      // if (value !== null) this.all_sell = value;
      // if (!this.sellFormData.price || !this.all_sell) {
      //   this.sellFormData.amount = "";
      //   return;
      // } else {
      //   this.sellFormData.amount =
      //     Math.round((this.all_sell / this.sellFormData.price) * 100000000) /
      //     100000000;
      // }
    },
    //現貨市價買入拖条"slider_value_shijia"
    sliderFn_shijia(e) {
      // userInfo.coin
      // buyFormData.price
      // buyFormData.amount
      // slider_value
      // if (e) {

      if (Number(this.userInfo.coin) <= 0) {
        // this.allBuyValueFn("");
        this.buyFormData_shijia.all_buy_shijia = ''
        return;
      }
      // 保留2位小數
      let coin_buy =
        Math.round(((Number(this.userInfo.coin) * e) / 100) * 100000000) /
        100000000;
      // this.allBuyValueFn(coin ? coin : "");
      coin_buy = this.floatingpoint.toolNumber(coin_buy);
      this.buyFormData_shijia.all_buy_shijia = coin_buy ? coin_buy : ""
      return;
      // }
      //this.all_buy = this.floatingpoint.bed(this.floatingpoint.mul(this.userInfo.coin,e),100)
    },
    //現貨市價賣出拖条"slider_value_shijia1"
    sliderFn_shijia1(e) {
      // userInfo.coin
      // buyFormData.price
      // buyFormData.amount
      // slider_value
      // if (e) {
      if (Number(this.userInfo.pair) <= 0) {
        this.sellFormData_shijia.all_sell_shijia = ''
        return;
      }
      // 保留2位小數
      let coin_sell =
        Math.round(((Number(this.userInfo.pair) * e) / 100) * 100000000) /
        100000000;
      // this.allBuyValueFn(coin_sell ? coin_sell : "");
      coin_sell = this.floatingpoint.toolNumber(coin_sell);
      this.sellFormData_shijia.all_sell_shijia = coin_sell ? coin_sell : ""
      return;
      // }
      //this.all_buy = this.floatingpoint.bed(this.floatingpoint.mul(this.userInfo.coin,e),100)
    },    
    // 市價交易監聽 -end


    //监听买入合计框all_buy
    allBuyValueFn(value = null) {
      if (value !== null) this.all_buy = value;
      if (!this.buyFormData.price || !this.all_buy) {
        this.buyFormData.amount = "";
        return;
      } else {
        this.buyFormData.amount =
          Math.round((this.all_buy / this.buyFormData.price) * 100000000) /
          100000000;
        this.buyFormData.amount = this.floatingpoint.toolNumber(this.buyFormData.amount);
      }
    },


    //现货买入拖条"slider_value"
    sliderFn(e = null) {
      // userInfo.coin
      // buyFormData.price
      // buyFormData.amount
      // slider_value
      // if (e) {
      if (Number(this.userInfo.coin) <= 0) {
        this.allBuyValueFn("");
        return;
      }
      const coin =
        Math.round(((Number(this.userInfo.coin) * e) / 100) * 100000000) /
        100000000;
      this.allBuyValueFn(coin ? coin : "");
      return;
      // }
      //this.all_buy = this.floatingpoint.bed(this.floatingpoint.mul(this.userInfo.coin,e),100)
    },

    //监听卖出价格框
    sellPriceFn(value = null) {
      if (value !== null) this.sellFormData.price = value;
      if (!this.sellFormData.price) {
        return;
      } else if (this.sellFormData.amount) {
        this.all_sell =
          Math.round(
            this.sellFormData.price * this.sellFormData.amount * 100000000
          ) / 100000000;
        this.all_sell = this.floatingpoint.toolNumber(this.all_sell);
      } else if (this.all_sell) {
        this.sellFormData.amount =
          Math.round((this.all_sell / this.sellFormData.price) * 100000000) /
          100000000;
        this.sellFormData.amount = this.floatingpoint.toolNumber(this.sellFormData.amount);
      }
    },

    //监听卖出数量框
    sellAmountFn(value = null) {
      if (value !== null) this.sellFormData.amount = value;
      if (!this.sellFormData.price || !this.sellFormData.amount) {
        this.all_sell = "";
        return;
      } else {
        this.all_sell =
          Math.round(
            this.sellFormData.price * this.sellFormData.amount * 100000000
          ) / 100000000;
        this.all_sell = this.floatingpoint.toolNumber(this.all_sell);
      }
    },

    //监听卖出合计框
    allSellValueFn(value = null) {
      if (value !== null) this.all_sell = value;
      if (!this.sellFormData.price || !this.all_sell) {
        this.sellFormData.amount = "";
        return;
      } else {
        this.sellFormData.amount =
          Math.round((this.all_sell / this.sellFormData.price) * 100000000) /
          100000000;
        this.sellFormData.amount = this.floatingpoint.toolNumber(this.sellFormData.amount);
      }
    },

    //现货卖出拖条
    sliderFn1(e = null) {
      // userInfo.coin
      // buyFormData.price
      // buyFormData.amount
      // slider_value
      // if (e) {
      if (Number(this.userInfo.pair) <= 0) {
        this.sellAmountFn("");
        return;
      }
      const pair =
        Math.round(((Number(this.userInfo.pair) * e) / 100) * 100000000) /
        100000000;
      this.sellAmountFn(pair ? pair : "");
      return;
      // }
    },

    //切换现货合约计算器
    handleClick(tab, event) {
      // console.log(tab, event);
      this.tab_index = Number(tab.index)
      // console.log(this.tab_index);
    },

    //切换订单列表
    handleClick1(tab, event) {
      // console.log(tab, event);
    },

    //现货订单列表
    fatchTradingList: function () {
      this.tradingList = [];
      if (this.$store.state.userToken == "") {
        this.tradingLoading = false;
        return;
      }
      this.tradingLoading = true;

      this.$request
        .post("v1/symbol/getOrderList", {
          page: this.pageNum1,
          pageSize: this.pageSize1,
          symbol: this.switch1
            ? this.$route.params.pair + "" + this.$route.params.coin
            : "",
          state: 0,
        })
        .then(({ data }) => {
          this.tradingList = data.list;
          this.total1 = data.total;
          this.tradingLoading = false;
        });
    },

    //切换现货当前/全部品种
    changeFn1: function (e) {
      this.switch1 = e;
      this.pageNum1 = 1;
      this.fatchTradingList();
    },

    //切换现货页码
    pageFn1: function (e) {
      this.pageNum1 = e;
      this.fatchTradingList();
    },

    //撤销现货订单
    onCancelTradingOrder: async function (id) {
      try {
        this.tradingLoading = true;
        let $data = await this.$request.post(
          "v1/symbol/revocation",
          {
            id: id,
          }
        );
        this.tradingLoading = false;
        // this.tradingList.splice(index, 1);
        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.fatchTradingList();
          },
        });
      } catch (error) {
        this.tradingLoading = false;
      }
    },

    //选择杠杆（通用） selectFn
    selectFn: function (e) {
      this.lever1 = e;
    },

    formatOrderProgress() {
      return `${this.lastDueTime}s`;
    },

    //微合约下单后返回结果接口
    fatchOrderItem: function () {
      if (!this.orderFormData.id) return;
      this.$request
        // .post("contract/item", this.orderFormData)
        .post(
          "v1/time_set/getInfo",
          this.orderFormData
        )
        .then(({ data }) => {
          if (data.state == 0) {
            window.setTimeout(() => {
              this.fatchOrderItem;
            }, 500);
          } else {
            this.orderFormData.state = data.state;
          }
        });
    },

    //微合约-下单
    submitOrderForm: async function () {
      try {
        await this.$refs.orderForm.validate();
        this.orderLoading = true;
        const { data } = await this.$request.post(
          "v1/time_set/create",
          this.orderFormData
        );
        this.orderLoading = false;
        this.lastDueTime = this.orderFormData.due_time;
        this.orderFormData.id = data.id;
        this.orderFormData.total = data.total;
        this.dueTimer = window.setInterval(() => {
          if (this.lastDueTime <= 0) {
            window.clearInterval(this.dueTimer);
            this.fatchOrderItem();
            return;
          }
          this.lastDueTime--;
        }, 1000);
      } catch (error) {
        this.orderLoading = false;
      }
    },

    onOrderShow: function (type = -1) {
      window.clearInterval(this.dueTimer);
      this.orderShow = true;
      this.orderFormData.id = 0;
      this.orderFormData.state = 0;
      if (type !== -1) {
        this.orderFormData.type = type;
      }
    },
    onOrderHide: function () {
      this.orderShow = false;
      window.clearInterval(this.dueTimer);
    },
  },
};
</script>
<style>
.white-form input {
  color: black !important;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.top-info {
  .pair-coin {
    font-size: 32px;
  }
}
.kline-box {
  position: relative;
  .kline-tools {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .kline-view {
    height: 500px;
    border: none;
    width: 1160px;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.mbp-buy {
  background-color: rgba(255, 59, 59, 0.07);
}
.mbp-sell {
  background-color: rgba(41, 194, 120, 0.07);
}

// 新增样式
.top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-textcontent {
  margin-right: 0;
}
.right-textcontent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.right-textcontent > div {
  min-width: 128px;
  padding-left: 20px;
  box-sizing: border-box;
}
.right-textcontent > div:nth-child(2) {
  min-width: 118px;
}
.right-textcontent > div:nth-last-child(1) {
  min-width: 148px;
}
.theme-title {
  font-size: 15px;
  margin: 20px 0 0;
  padding: 0 20px;
  box-sizing: border-box;
}
.theme-title span {
  padding-left: 10px;
  border-left: 3px solid #f9a825;
  color: #333;
}
.top-info .left-textcontent h1 {
  font-size: 34px;
}
.radio-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.radio-group label {
  margin: 0 5px 10px 5px !important;
}
.newstyle {
  padding: 20px;
}
.newstyle-col {
  padding-top: 20px !important;
}

// 顶部信息样式
.top-text {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebedf0;
  box-sizing: border-box;
}
.top-text .top-text-item {
  flex: 1;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.top-text-h {
  font-size: 14px;
  color: #999999;
}
.top-text-p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.in-select {
  width: 120px;
  font-size: 12px;
}
.pagination-box {
  text-align: center;
  padding-top: 10px;
  box-sizing: border-box;
}

// 订单类型
.etab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #e3e6e9;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.title-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.title-label-item {
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 14px;
}
.title-label-cli {
  width: 1px;
  height: 16px;
  background: #999;
}
.title-label .active {
  color: #f9a825;
}

// 持仓列表
.etab-top {
  padding: 5px 0 10px;
  justify-content: flex-end;
}

//新增拖条
.slider_box {
  // padding: 0 10px;
  box-sizing: border-box;
}
.slider_style_class {
  // height: 4px!important;
  transform: scale(0.8);
}
.slider_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider_box_into {
  width: 85%;
}
.slider_box_tooltip {
  width: 15%;
}

// k线水印
.kline-view {
  background: url(../../assets/index/shui.png) no-repeat 10px 266px;
}

// 以當前最優價格交易
.youjia{
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  line-height: 20px;
  padding: 8px 10px;
  color: #999;
  box-sizing: border-box;
  background: #f1f1f1;
  border: 1px solid #dfdfdf;
  text-align: center;
}
</style>